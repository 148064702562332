// MUI Components
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';


const Space = () => {
    return (
        <>
        <div className="content-header pb-6">
            Space Name
        </div>
        <div className="content-body">
        <form>
        <TextField
            className='textfield w-full'
            id="space-name"
            label="Space Name"
          />
          <Button>*Upload Image</Button>
          <Button>Upload Video</Button>
          <br/>
          Add Hotspots
          <table class="table-auto border w-full">
                    <thead>
                        <tr>
                            <th class="font-bold p-2 border-b text-left w-2">No</th>
                            <th class="font-bold p-2 border-b text-left">Hotspot Name</th>
                            <th class="font-bold p-2 border-b text-left">Hotspot Type</th>
                            <th class="font-bold py-2 px-4 border-b text-left">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="p-2 border-b text-left">1</td>
                            <td class="p-2 border-b text-left">Space 1</td>
                            <td class="p-2 border-b text-left">Artefact</td>
                            <td class="py-2 px-4 border-b text-left">Edit/Delete</td>
                        </tr>
                        <tr>
                            <td class="p-2 border-b text-left">1</td>
                            <td class="p-2 border-b text-left">Space 1</td>
                            <td class="p-2 border-b text-left">Image Popup</td>
                            <td class="py-2 px-4 border-b text-left">Edit/Delete</td>
                        </tr>
                        <tr>
                            <td class="p-2 border-b text-left">1</td>
                            <td class="p-2 border-b text-left">Space 1</td>
                            <td class="p-2 border-b text-left">Space</td>
                            <td class="py-2 px-4 border-b text-left">Edit/Delete</td>
                        </tr>
                    </tbody>
                </table>
          <br/>
          Hitory
          <table class="table-auto border w-full">
                    <thead>
                        <tr>
                            <th class="font-bold p-2 border-b text-left w-2">Year</th>
                            <th class="font-bold p-2 border-b text-left">Preview</th>
                            <th class="font-bold py-2 px-4 border-b text-left">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="p-2 border-b text-left">1950</td>
                            <td class="p-2 border-b text-left">Space 1</td>
                            <td class="py-2 px-4 border-b text-left">Upload Image / Delete Image</td>
                        </tr>
                        <tr>
                            <td class="p-2 border-b text-left">1950</td>
                            <td class="p-2 border-b text-left">Space 1</td>
                            <td class="py-2 px-4 border-b text-left">Upload Image / Delete Image</td>
                        </tr>
                        <tr>
                            <td class="p-2 border-b text-left">1950</td>
                            <td class="p-2 border-b text-left">Space 1</td>
                            <td class="py-2 px-4 border-b text-left">Upload Image / Delete Image</td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                <Button>Create/Save</Button>
                <Button>Cancel</Button>
        </form>
        </div>
        
        </>
    )
}

export default Space;