import { Navigate, Outlet } from "react-router-dom";
import { useStateContext } from "../contexts/contextProvider";


// CSS
import "./../styles/DefaultLayout.scss"
import Sidebar from "./Sidebar";
import Header from "./Header";

const DefaultLayout = () => {

    const { user, token } = useStateContext();

    if (!token) {
        return <Navigate to={"/login"} />
    }

    return (
        <div className="page-container w-full h-screen">
            <Header />
            <div className="sidebar-container">
            <Sidebar />
            <div className="content w-full p-6">

            <Outlet />
            </div>
            </div>
        </div>
    )
}

export default DefaultLayout;