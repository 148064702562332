import { Navigate, Outlet } from "react-router-dom";
import { useStateContext } from "../contexts/contextProvider";

const GuestLayout = () => {

    const {token} = useStateContext();

    if(token){
        return <Navigate to={'/spaces'}/>
    }

    return (
        <>
        <Outlet/>
        </>
    )
}

export default GuestLayout;