import { useEffect, useState } from "react";
import axiosClient from "../axios-client";


const Spaces = () => {

    const [spaces, setSpaces] = useState([]);

useEffect(()=>{
    fetch(`${process.env.REACT_APP_API_URL}/space`, {method: 'GET'});
},[]);

    return (
        <>
            <div className="content-header pb-6">
                Spaces
            </div>
            <div className="content-body">

                <table className="table-auto border w-full">
                    <thead>
                        <tr>
                            <th className="font-bold p-2 border-b text-left w-2">No</th>
                            <th className="font-bold p-2 border-b text-left w-full">Space Name</th>
                            <th className="font-bold py-2 px-4 border-b text-left">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="p-2 border-b text-left">1</td>
                            <td className="p-2 border-b text-left">Space 1</td>
                            <td className="py-2 px-4 border-b text-left">Edit/Delete</td>
                        </tr>
                        <tr>
                            <td className="p-2 border-b text-left">2</td>
                            <td className="p-2 border-b text-left">Space 2</td>
                            <td className="py-2 px-4 border-b text-left">Edit/Delete</td>
                        </tr>
                        <tr>
                            <td className="p-2 border-b text-left">3</td>
                            <td className="p-2 border-b text-left">Space 3</td>
                            <td className="py-2 px-4 border-b text-left">Edit/Delete</td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </>
    )
}

export default Spaces;