
// MUI Components
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

// CSS
import "./../styles/Login.scss";
import { createRef, useRef, useState } from 'react';
import { useStateContext } from '../contexts/contextProvider';



const Login = () => {

const [email, setEmail] = useState();
const [password, setPassword] = useState();
const {setUser, setToken} = useStateContext();


const onSubmit = (e) => {

  e.preventDefault();

  console.log(email, password);


  if(email === 'adminpixel' || password === 'adminpixel'){
      setToken('abcd');
  }else{
    alert("Wrong username or password");
  }

}

const onEmailEntered = (e) => {
  const val = e.target.value;
  setEmail(val);
}
  
const onPasswordEntered = (e) => {
  const val = e.target.value;
  setPassword(val);
}
  
  return (
    <div className="login-container">
      <form className="login-box grid grid-rows-1 gap-5 p-5" onSubmit={onSubmit}>
        <div className='login-title text-center font-bold text-2xl'>
          Login
        </div>
        <div>
          <TextField
            className='textfield w-full'
            id="email"
            label="Username"
            type="text"
            value={email}
            onChange={onEmailEntered}
          />
        </div>
        <div>
          <TextField
            className='textfield w-full'
            id="password"
            label="Password"
            type="password"
            value={password}
            onChange={onPasswordEntered}
          />
        </div>
        <div>
          <Button className='w-full' variant="contained" type='submit'>Login</Button>
        </div>
      </form>
    </div>
  )
}

export default Login;